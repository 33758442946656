<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("farmers.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-3 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("farmers.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <!-- <button class="btn btn-success btn-add mr-2" @click="onImport">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                Import Petani
              </button> -->
              <button
                class="btn btn-success btn-add ml-auto mr-4"
                @click="openModalTambah"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("farmers.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('farmers.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <!-- <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-3"></b-tabs>
              </b-popover>
            </div> -->
          </div>
          <div class="table-borderless mx-3">
            <b-table
              hover
              :items="dataPetani"
              :fields="[
                {
                  label: '#',
                  key: 'index'
                  // sortable: true,
                },
                {
                  label: $t('farmers.headers[0]'),
                  key: 'name'
                  // sortable: true,
                },
                {
                  label: $t('farmers.headers[1]'),
                  key: 'sp.name'
                  // sortable: true,
                },
                {
                  label: $t('farmers.headers[2]'),
                  key: 'tss.name'
                  // sortable: true,
                },
                {
                  label: $t('farmers.headers[3]'),
                  key: 'luas',
                  sortable: true
                },
                {
                  label: $t('farmers.headers[4]'),
                  key: 'ucrops.crop'
                  // sortable: true,
                },
                {
                  label: $t('farmers.headers[5]'),
                  key: 'actions',
                  class: 'ssp-14'
                  // sortable: false,
                }
              ]"
              sort-icon-left
              show-empty
              small
              :busy="loading"
              responsive="true"
              class="petani-table"
            >
              <!-- <template v-slot:head(selected)>
                <input
                  type="checkbox"
                  @click="selectAll"
                  v-model="allSelected"
                />
              </template>
              <template v-slot:cell(selected)="id">
                <div @click.stop>
                  <input type="checkbox" v-model="petaniIds" @click="select" />
                </div>
              </template> -->

              <template #cell(index)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item">
                    {{ ++data.index + (currPage - 1) * perPage }}
                  </li>
                </ul>
              </template>

              <template #cell(name)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Farmer Name"
                >
                  <li class="list-inline-item ssp-16-700">
                    {{ data.item.name }}
                  </li>
                  <br />
                  <li class="list-inline-item">
                    {{ data.item.hp }}
                  </li>
                </ul>
              </template>
              <template #cell(sp.name)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="SP Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.sp ? data.item.sp.name : "-" }}
                  </li>
                </ul>
              </template>
              <template #cell(tss.name)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="TSS Name"
                >
                  <li class="list-inline-item">
                    {{ data.item.tss ? data.item.tss.name : "-" }}
                  </li>
                </ul>
              </template>
              <template #cell(luas)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Luas Lahan"
                >
                  <li class="list-inline-item">
                    {{ data.item.luas + " Ha" }}
                  </li>
                </ul>
              </template>
              <template #cell(ucrops.crop)="data">
                <ul
                  class="list-inline d-inline-block mw-100"
                  style="width: max-content"
                  title="Budidaya Tanaman"
                >
                  <li class="list-inline-item">
                    {{ data.item.ucrops.crop }}
                  </li>
                </ul>
              </template>
              <template v-slot:cell(actions)="{ item }">
                <ul
                  style="width: max-content"
                  class="list-inline d-inline-block mw-100"
                >
                  <div class="action-wrapper font-12">
                    <i
                      class="ri-eye-fill ri-lg ri-mid action-btn"
                      @click="openModalDetail(item)"
                    ></i>
                    <i
                      class="ri-edit-fill ri-lg ri-mid action-btn"
                      @click="onEdit(item)"
                    ></i>
                    <!-- <i
                      class="ri-delete-back-2-fill ri-lg ri-mid action-btn"
                      @click="delPetani(item.id)"
                    ></i> -->
                  </div>
                </ul>
              </template>
            </b-table>

            <!-- <b-table-simple responsive style="overflow: hidden;">
              <b-thead class="bv-head">
                <b-tr>
                  <b-th><input type="checkbox" @click="selectAll" v-model="allSelected"></b-th>
                  <b-th v-for="hd in $t('farmers.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="petani in dataPetani" :key="petani.id">
                  <b-td><input type="checkbox" :value="petani.id" v-model="petaniIds" @click="select"></b-td>
                  <b-td>
                    <b-tr class="ssp-16-700">{{ petani.name }}</b-tr>
                    <b-tr>{{ petani.hp }}</b-tr>
                  </b-td>
                  <b-td>{{ petani.sp.name }}</b-td>
                  <b-td>{{ petani.tss.name }}</b-td>
                  <b-td>{{ `${petani.luas} Ha` }}</b-td>
                  <b-td>{{ petani.ucrops.crop }}</b-td>
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i class="ri-eye-fill ri-lg ri-mid action-btn" @click="delPetani(petani.id)"></i>
                      <i class="ri-edit-fill ri-lg ri-mid action-btn" @click="onEdit(petani)"></i>
                      <i class="ri-delete-back-2-fill ri-lg ri-mid action-btn" @click="delPetani(petani.id)"></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple> -->

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-petani" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ modalTitle }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <!-- <p class="">Modal Body with button</p> -->
        <form @submit="onSubmit">
          <div class="form-group">
            <label for="nama-petani" class="ssp-16 text-black">
              {{ $t("farmers.label.name") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              type="text"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.nama.$error }"
              id="nama-petani"
              v-model="$v.formTambah.nama.$model"
              :placeholder="$t('farmers.placeholder.name')"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.nama.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.tss") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.tss"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.tss')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.id_tss.$error
              }"
              v-model="$v.formTambah.id_tss.$model"
              :options="dataTSS.map(tss => tss.id)"
              :custom-label="opt => dataTSS.find(x => x.id == opt).name"
              :disabled="isDisabled"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.id_tss.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">
              {{ $t("farmers.label.sp") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <!-- <custom-select :options="dataSP" valueProp="id" label="name" v-model="formTambah.id_sp" :searchable="true"
              trackBy="name" :placeholder="$t('monthlyplan.placeholder.sp')" /> -->
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.sp"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.sp')"
              :class="{
                'invalid-form rounded-input': $v.formTambah.id_sp.$error
              }"
              v-model="$v.formTambah.id_sp.$model"
              :options="dataSP"
              label="name"
              :allow-empty="false"
              :disabled="isDisabled"
              deselect-label="Can't remove this value"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.id_sp.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black"
              >{{ $t("farmers.label.subdistrict") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <!-- <custom-select :options="dataProv" valueProp="id" label="province" v-model="formTambah.id_prov"
              :searchable="true" trackBy="province" placeholder="Pilih Provinsi" /> -->
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.kec"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.subdistrict')"
              v-model="$v.formTambah.id_kec.$model"
              :class="{
                'invalid-form rounded-input': $v.formTambah.id_kec.$error
              }"
              :options="dataKecamatan"
              label="kecamatan"
              :allow-empty="false"
              :disabled="isDisabled"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.id_kec.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
            <!-- <custom-select v-else placeholder="Pilih Nama Kecamatan" v-model="formTambah.id_kec"
              :options="dataKec.map(kec => kec.id)" :custom-label="opt => dataKec.find(x => x.id == opt).kecamatan"
              @select="resetKec" @close="fetchKelurahan" :allow-empty="false" :disabled="isDisabled">
            </custom-select> -->
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black"
              >{{ $t("farmers.label.village") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.kel"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.village')"
              v-model="$v.formTambah.id_kel.$model"
              :class="{
                'invalid-form rounded-input': $v.formTambah.id_kel.$error
              }"
              :options="dataKelurahan"
              label="kelurahan"
              :disabled="isDisabled"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.id_kel.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="alamat-petani" class="ssp-16 text-black"
              >{{ $t("farmers.label.address") }}
              <span v-if="!isDetail" class="text-red">*</span></label
            >
            <textarea
              class="form-control no-resize"
              :class="{ 'invalid-form': $v.formTambah.alamat.$error }"
              id="alamat-petani"
              cols="30"
              rows="5"
              :placeholder="$t('farmers.placeholder.address')"
              v-model="$v.formTambah.alamat.$model"
              :disabled="isDisabled"
            ></textarea>
            <div class="text-sm text-red" v-if="$v.formTambah.alamat.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="hp-petani" class="ssp-16 text-black"
              >{{ $t("farmers.label.phone")
              }}<span v-if="!isDetail" class="text-red">*</span></label
            >
            <input
              type="number"
              class="form-control"
              :class="{ 'invalid-form': $v.formTambah.no_hp.$error }"
              id="hp-petani"
              v-model="$v.formTambah.no_hp.$model"
              :placeholder="$t('farmers.placeholder.phone')"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.no_hp.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ll-petani" class="ssp-16 text-black"
              >{{ $t("farmers.label.landarea") }}
              <span v-if="!isDetail" class="text-red">*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="ll-petani"
              v-model="$v.formTambah.luas.$model"
              :class="{ 'invalid-form': $v.formTambah.luas.$error }"
              :placeholder="$t('farmers.placeholder.landarea')"
              :disabled="isDisabled"
            />
            <div class="text-sm text-red" v-if="$v.formTambah.luas.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black"
              >{{ $t("farmers.label.main_cult") }}
              <span v-if="!isDetail" class="text-red">*</span>
            </label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.crop"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.main_cult')"
              v-model="$v.formTambah.id_crop.$model"
              :class="{
                'invalid-form invalid-input': $v.formTambah.id_crop.$error
              }"
              :options="dataTanaman.map(crop => crop.id)"
              :custom-label="opt => dataTanaman.find(x => x.id == opt).crop"
              :disabled="isDisabled"
            >
            </custom-select>
            <div class="text-sm text-red" v-if="$v.formTambah.id_crop.$error">
              <div>{{ $t("feedback.required") }}</div>
            </div>
          </div>
          <div class="form-group">
            <label class="typo__label ssp-16 text-black">{{
              $t("farmers.label.other_cult")
            }}</label>
            <input
              v-if="isDetail"
              class="form-control"
              type="text"
              v-model="formDetail.ocrop"
              disabled
            />
            <custom-select
              v-else
              :placeholder="$t('farmers.placeholder.other_cult')"
              v-model="formTambah.id_ocrop"
              :options="dataTanaman.map(crop => crop.id)"
              :custom-label="opt => dataTanaman.find(x => x.id == opt).crop"
              :disabled="isDisabled"
            >
            </custom-select>
          </div>
          <button
            v-if="!isDetail"
            type="submit"
            class="btn btn-primary ssp-14 w-100 my-3 py-2"
          >
            {{ $t("global.save") }}
          </button>
        </form>
      </template>
    </b-modal>
    <b-modal id="modal-import-petani" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">{{ $t("global.import") }}</h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit="importPetani">
          <div class="form-group">
            <label class="ssp-16 text-black">
              {{ $t("global.excel_label") }}
              <span class="text-red"> *</span>
            </label>
            <b-form-file
              v-model="dataImport"
              :placeholder="$t('global.excel_pch')"
              drop-placeholder="Drop file here..."
            >
            </b-form-file>
          </div>
          <div class="form-group mb-0">
            <button
              type="button"
              class="btn btn-success"
              @click="generateTemplate"
            >
              Template Excel
            </button>
          </div>
          <button type="submit" class="btn btn-primary ssp-14 w-100 my-3 py-2">
            Submit
          </button>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";

import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import {
  Q_LIST_FARMER,
  Q_LIST_USER,
  Q_LIST_KECAMATAN,
  Q_LIST_KELURAHAN,
  Q_LIST_CROP,
  M_ADD_FARMER,
  M_EDIT_FARMER
} from "@/graphql/super_admin";
import { RoleType } from "@/graphql/enum.type.js";

// import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import JwtService from "@/core/services/jwt.service";
import * as XLSX from "xlsx";
import axios from "axios";
import Axios from "axios";

export default {
  components: {
    Card,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      loading: true,
      dataPetani: [],
      optionRole: [RoleType.TSS, RoleType.SP],
      modalTitle: null,
      isDisabled: null,
      isDetail: null,
      isEdit: false,

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",

      fieldsPetani: [
        {
          label: "#",
          key: "index"
          // sortable: true,
        },
        {
          label: this.$t("farmers.headers[1]"),
          key: "name"
          // sortable: true,
        },
        {
          label: this.$t("farmers.headers[2]"),
          key: "sp.name"
          // sortable: true,
        },
        {
          label: this.$t("farmers.headers[3]"),
          key: "tss.name"
          // sortable: true,
        },
        {
          label: this.$t("farmers.headers[4]"),
          key: "luas",
          sortable: true
        },
        {
          label: this.$t("farmers.headers[5]"),
          key: "ucrops.crop"
          // sortable: true,
        },
        {
          label: this.$t("farmers.headers[6]"),
          key: "actions",
          class: "ssp-14"
          // sortable: false,
        }
      ],

      dataSP: [],
      dataTSS: [],
      idKabSPSelected: null,
      dataKec: [],
      dataKel: [],
      dataTanaman: [],
      dataImport: null,

      formTambah: {
        id: "",
        nama: "",
        id_sp: "",
        id_tss: "",
        id_kec: "",
        id_kel: "",
        alamat: "",
        no_hp: "",
        luas: "",
        id_crop: "",
        id_ocrop: ""
      },

      formDetail: {
        sp: "",
        tss: "",
        kec: "",
        kab: "",
        crop: "",
        ocrop: ""
      },

      petaniIds: [],
      selected: [],
      allSelected: false
    };
  },
  validations: {
    formTambah: {
      nama: { required },
      id_sp: { required },
      id_tss: { required },
      id_kec: { required },
      id_kel: { required },
      alamat: { required },
      no_hp: { required },
      luas: { required },
      id_crop: { required },
      id_ocrop: { required }
    }
  },
  apollo: {
    listPetani: {
      query: () => Q_LIST_FARMER,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataPetani = data.listPetani.petanis;
        this.totalPages = data.listPetani.totalPage;
        this.totalData = data.listPetani.total;
      },
      error(err) {
        this.catchError(err);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listTSS: {
      query: () => Q_LIST_USER,
      variables() {
        return {
          page: 1,
          limit: 999999,
          keyword: "",
          role: this.optionRole[0]
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataTSS = data.listUser.users;
      }
    },
    listSP: {
      query: () => Q_LIST_USER,
      variables() {
        return {
          page: 1,
          limit: 999999,
          keyword: "",
          role: this.optionRole[1]
        };
      },
      update: data => data.listUser,
      result({ data }) {
        this.dataSP = data.listUser.users;
      }
    },
    listKecamatan: {
      query: Q_LIST_KECAMATAN,
      variables() {
        return {
          keyword: "",
          kabupaten_id:
            !this.isEdit && this.formTambah.id_sp
              ? this.formTambah.id_sp.detail[0].kabupaten_id
              : ""
        };
      },
      result({ data }) {
        this.dataKecamatan = data.listKecamatan.kecamatans;
      }
    },
    listKelurahan: {
      query: Q_LIST_KELURAHAN,
      variables() {
        return {
          keyword: "",
          kecamatan_id: this.formTambah.id_kec ? this.formTambah.id_kec.id : ""
        };
      },
      result({ data }) {
        this.dataKelurahan = data.listKelurahan.kelurahans;
      }
    }
  },
  methods: {
    openModalTambah() {
      this.modalTitle = "Tambah Data Petani Baru";
      this.isDisabled = false;
      this.isDetail = false;
      this.isEdit = false;
      this.$bvModal.show("modal-petani");
      this.resetForm();
    },
    openModalDetail(data) {
      this.modalTitle = this.$t("farmers.details");
      this.isDisabled = true;
      this.isDetail = true;
      this.$bvModal.show("modal-petani");

      this.formTambah.nama = data.name;
      this.formDetail.sp = data.sp.name;
      this.formDetail.tss = data.tss.name;
      this.formDetail.kec = data.kec.kecamatan;
      this.formDetail.kel = data.kel.kelurahan;
      this.formTambah.alamat = data.alamat;
      this.formTambah.no_hp = data.hp;
      this.formTambah.luas = data.luas;
      this.formDetail.crop = data.ucrops.crop;
      this.formDetail.ocrop = data.ocrops.crop;
    },
    onImport() {
      this.$bvModal.show("modal-import-petani");
    },
    importPetani(e) {
      e.preventDefault();
      let formData = new FormData();
      formData.append("file", this.dataImport);
      axios
        .post(`${process.env.VUE_APP_URL_GRAPHQL}/produk/import`, formData, {
          headers: {
            Authorization: "Bearer " + JwtService.getToken(),
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response) {
            this.$bvModal.hide("modal-import-produk");
            this.dataImport = null;
            this.$apollo.queries.listProduk.refetch();
            this.toastAlert(this.$t("alert.import"));
          }
        })
        .catch(err => {
          this.errorAlert(err);
        });
    },
    generateTemplate() {
      const wb = XLSX.utils.book_new();
      let worksheet = XLSX.utils.json_to_sheet([{}]);
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            "Kategori",
            "Nama Bahan",
            "Jumlah Terkecil",
            "Satuan Terkecil",
            "Harga / Satuan Terkecil"
          ]
        ],
        { origin: "A1" }
      );
      worksheet["!cols"] = [
        { width: 8 },
        { width: 20 },
        { width: 14 },
        { width: 14 },
        { width: 22 }
      ];
      XLSX.utils.book_append_sheet(wb, worksheet, `Sheet 1`);
      XLSX.writeFile(wb, `Template Import User.xlsx`);
    },
    async onEdit(data) {
      this.modalTitle = "Edit Data Petani";
      this.isDisabled = false;
      this.isDetail = false;
      this.isEdit = true;
      this.resetForm();

      this.idKabSPSelected = data.kab_id;
      // await this.fetchKecamatan();

      this.formTambah.id = data.id;
      this.formTambah.nama = data.name;
      this.formTambah.id_sp = data.sp;
      this.formTambah.id_tss = data.tss.id;
      this.formTambah.id_kec = data.kec;

      // await this.fetchKelurahan();

      this.formTambah.id_kel = data.kel;
      this.formTambah.alamat = data.alamat;
      this.formTambah.no_hp = data.hp;
      this.formTambah.luas = data.luas;
      this.formTambah.id_crop = data.ucrops.id;
      this.formTambah.id_ocrop = data.ocrops.id;

      this.$bvModal.show("modal-petani");
    },
    resetForm() {
      this.$v.$reset();
      this.formTambah.nama = "";
      this.formTambah.id_sp = "";
      this.formTambah.id_tss = "";
      this.formTambah.id_kec = "";
      this.formTambah.id_kel = "";
      this.formTambah.alamat = "";
      this.formTambah.no_hp = "";
      this.formTambah.luas = "";
      this.formTambah.id_crop = "";
      this.formTambah.id_ocrop = "";
    },
    closeModalTambah() {
      this.$bvModal.hide("modal-petani");
      this.resetForm();
    },
    // fetchTSS() {
    //   return new Promise((resolve, reject) => {
    //     const result = this.$apollo.query({
    //       query: Q_LIST_USER,
    //       variables: {
    //         page: 1,
    //         limit: 999999,
    //         keyword: "",
    //         role: this.optionRole[0]
    //       }
    //     });
    //     if (resolve) {
    //       resolve(result);
    //     } else {
    //       reject(result);
    //     }
    //   })
    //     .then(response => {
    //       this.dataTSS = response.data.listUser.users;
    //     })
    //     .catch(err => {
    //       this.errorAlert(err);
    //     });
    // },
    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    resetSP() {
      this.formTambah.id_kec = "";
    },
    setIDKab() {
      this.idKabSPSelected = this.formTambah.id_sp.detail[0].kabupaten_id;
    },
    async fetchKecamatan() {
      // const idKab = ;
      if (this.idKabSPSelected) {
        try {
          const result = await this.$apollo.query({
            query: Q_LIST_KECAMATAN,
            variables: {
              kabupaten_id: this.idKabSPSelected
            }
          });
          this.dataKec = result.data.listKecamatan.kecamatans;
        } catch (error) {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: error,
            width: 400,
            confirmButtonText: "OK"
          });
        }
      }
    },
    resetKec() {
      this.dataKel = [];
      this.formTambah.id_kel = "";
    },
    async fetchKelurahan() {
      try {
        const result = await this.$apollo.query({
          query: Q_LIST_KELURAHAN,
          variables: {
            kecamatan_id: this.formTambah.id_kec
          }
        });
        this.dataKel = result.data.listKelurahan.kelurahans;
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Error!",
          text: error,
          width: 400,
          confirmButtonText: "OK"
        });
      }
    },
    fetchTanaman() {
      return new Promise((resolve, reject) => {
        const result = this.$apollo.query({
          query: Q_LIST_CROP,
          variables: {
            page: 1,
            limit: 9999,
            search: { keyword: "" }
          }
        });
        if (resolve) {
          resolve(result);
        } else {
          reject(result);
        }
      })
        .then(response => {
          this.dataTanaman = response.data.listCropName.crops;
        })
        .catch(err => {
          this.$swal.fire({
            icon: "error",
            title: "Error!",
            text: err,
            width: 400,
            confirmButtonText: "OK"
          });
        });
    },
    // onSubmit(e) {
    //   e.preventDefault();
    //   console.log(this.formTambah);
    // },
    async onSubmit(ev) {
      ev.preventDefault();
      this.$v.$touch();
      if (!this.$v.$error) {
        if (this.isEdit) {
          // console.log(this.formTambah);
          return new Promise((resolve, reject) => {
            const addFarmer = this.$apollo.mutate({
              mutation: M_EDIT_FARMER,
              variables: {
                id: this.formTambah.id,
                name: this.formTambah.nama,
                tss_id: this.formTambah.id_tss,
                sp_id: this.formTambah.id_sp ? this.formTambah.id_sp.id : "",
                kec_id: this.formTambah.id_kec ? this.formTambah.id_kec.id : "",
                kel_id: this.formTambah.id_kel ? this.formTambah.id_kel.id : "",
                alamat: this.formTambah.alamat,
                hp: this.formTambah.no_hp,
                luas: parseFloat(this.formTambah.luas),
                ucrop_id: this.formTambah.id_crop,
                ocrop_id: this.formTambah.id_ocrop
              }
            });
            if (resolve) {
              resolve(addFarmer);
            } else {
              reject(addFarmer);
            }
          })
            .then(response => {
              this.$apollo.queries.listPetani.refetch();
              if (response) {
                this.toastAlert(this.$t("alert.edit"));
                this.closeModalTambah();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        } else {
          return new Promise((resolve, reject) => {
            const addFarmer = this.$apollo.mutate({
              mutation: M_ADD_FARMER,
              variables: {
                name: this.formTambah.nama,
                tss_id: this.formTambah.id_tss,
                sp_id: this.formTambah.id_sp ? this.formTambah.id_sp.id : "",
                kec_id: this.formTambah.id_kec ? this.formTambah.id_kec.id : "",
                kel_id: this.formTambah.id_kel ? this.formTambah.id_kel.id : "",
                alamat: this.formTambah.alamat,
                hp: this.formTambah.no_hp,
                luas: parseInt(this.formTambah.luas),
                ucrop_id: this.formTambah.id_crop,
                ocrop_id: this.formTambah.id_ocrop
              }
            });
            if (resolve) {
              resolve(addFarmer);
            } else {
              reject(addFarmer);
            }
          })
            .then(response => {
              this.$apollo.queries.listPetani.refetch();
              if (response) {
                this.toastAlert(this.$t("alert.save"));
                this.closeModalTambah();
              }
            })
            .catch(err => {
              this.$swal.fire({
                icon: "error",
                title: "Error!",
                text: err.message,
                width: 400,
                confirmButtonText: "OK"
              });
            });
        }
      }
    },
    // delPetani(id) {
    //   this.deleteConfirm().then(async result => {
    //     if (result.isConfirmed) {
    //       return new Promise((resolve, reject) => {
    //         const delFarmer = this.$apollo.mutate({
    //           mutation: M_DEL_FARMER,
    //           variables: {
    //             id: id
    //           }
    //         });
    //         if (resolve) {
    //           resolve(delFarmer);
    //         } else {
    //           reject(delFarmer);
    //         }
    //       })
    //         .then(response => {
    //           this.$apollo.queries.listPetani.refetch();
    //           if (response) {
    //             this.toastAlert(this.$t("alert.delete"));
    //             // this.$apollo.queries.fetchPetani.refetch()
    //           }
    //         })
    //         .catch(err => {
    //           this.errorAlert(err);
    //         });
    //     }
    //   });
    // },
    selectAll() {
      this.petaniIds = [];
      if (!this.allSelected) {
        for (let i in this.dataPetani) {
          this.petaniIds.push(this.dataPetani[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    async handleExport() {
      this.isLoading = true;
      await Axios.get(process.env.VUE_APP_URL_GRAPHQL + "/petani/excel", {
        responseType: "arraybuffer",
        headers: {
          Authorization: "Bearer " + JwtService.getToken(),
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `petani-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    this.fetchTanaman();
  }
};
</script>

<style>
.petani-table thead {
  background-color: #fafafa;
  border-radius: 50px;
}

.petani-table tbody tr td {
  /* background-color: red; */
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  vertical-align: middle;
}

.petani-table tbody tr td ul {
  margin-bottom: 0;
}
</style>
<!-- <style src="@vueform/multiselect/themes/default.css">
</style> -->
